import { detect } from 'detect-browser';
import Cookies from 'js-cookie';
import cfIpcountry from '../cfIpcountry';
import { adUnitRegistry } from './dfpConstants';

const MODAL_DEBOUNCE_COOKIE_NAME = 'modalShownIn5Minutes';

export function checkInterstitialAvailability() {
  const $modal = Array.from(document.querySelectorAll('.rl-modal'));

  const browser = detect();
  // To avoid being caught by better ads standard, we don't show to chrome browser
  const isNotChrome = browser && browser.name !== 'chrome';
  // XX is set for local dev testing.
  const inTaiwan = cfIpcountry === 'TW' || cfIpcountry === 'XX';
  const modalShownIn5Minutes = Cookies.get(MODAL_DEBOUNCE_COOKIE_NAME);
  const shouldShowModal =
    $modal.length &&
    document.body.clientWidth <= 640 &&
    isNotChrome &&
    inTaiwan &&
    !modalShownIn5Minutes;

  if (!shouldShowModal) {
    // Prevent being requested
    $modal.forEach((node) => {
      delete adUnitRegistry[node.id];
    });
  }

  return shouldShowModal;
}

const removeInterstitialBackgroundAdvertiserIds = [
  // 4557913199, // Clickforce
  5186035563, // Ad2iction
  4462350630 // OneAd
];

export function fixInterstitialModal(
  event: googletag.events.ImpressionViewableEvent
) {
  const id = event.slot.getSlotElementId();
  if (!/_Interstitial$/.test(id)) return;
  const interstitial = document.getElementById(id);
  if (!interstitial) return;

  const removeInterstitial = () => {
    interstitial.parentNode!.removeChild(interstitial);
  };

  const resp = event.slot.getResponseInformation();
  if (resp === null) {
    removeInterstitial();
    return;
  }

  // For creative preview URL, it returns empty resp! Be careful when working with adops.
  const { advertiserId } = resp;
  Cookies.set(MODAL_DEBOUNCE_COOKIE_NAME, 'true', { expires: 1 / 288 });

  const shouldRemoveBackground =
    removeInterstitialBackgroundAdvertiserIds.indexOf(
      advertiserId as number
    ) !== -1;

  const close = document.createElement('a');
  close.addEventListener('click', () => {
    removeInterstitial();
  });
  close.className = 'close-button';
  interstitial.classList.add('rl-modal-backdrop');
  interstitial.insertAdjacentElement('beforeend', close);

  if (shouldRemoveBackground) {
    interstitial.style.setProperty('display', 'none', 'important');
  }
}
