export const googletag =
  window.googletag ||
  // eslint-disable-next-line no-console
  (console.warn('window.googletag is not found'), { cmd: [] });

/* eslint-disable no-underscore-dangle */
export const adUnitRegistry =
  window.__iCook_adUnitRegistry ||
  // eslint-disable-next-line no-console
  (console.warn('window.__iCook_adUnitRegistry is not found'), {});

export const DFP_ID = '40828883';

export const CURRENT_INGREDIENTS_TARGETING = ['醬油'];
