/* global googletag, pbjs */

import Cookies from 'js-cookie';
import {
  isMobile,
  shouldPerformPrebid
} from '../../assets/javascripts/components/ad/helpers';
import {
  AUCTION_DELAY,
  registerTDIDCallback,
  userIds
} from '../../assets/javascripts/components/ad/ids';
import prebidAdUnits from '../../assets/javascripts/components/ad/prebidAdUnits';
import { getUserId } from '../../assets/javascripts/reactjs/utils';
const getVisitor = () => Cookies.get('visitor');

if (shouldPerformPrebid) {
  window.pbjs = window.pbjs || {};
  pbjs.que = window.pbjs.que || [];

  window.googletag = window.googletag || {};
  googletag.cmd = window.googletag.cmd || [];

  // Disable GTP auto load
  window.googletag.cmd.push(() => {
    window.googletag.pubads().disableInitialLoad();
    window.googletag
      .pubads()
      .setPublisherProvidedId(getUserId() || getVisitor());
  });

  const adUnitRegistry = window.__iCook_adUnitRegistry;

  const adUnits = prebidAdUnits.filter((adunit) => {
    const adunitSetting = adUnitRegistry[adunit.code];
    return adunitSetting && adunitSetting.prebid;
  });

  const REFRESH_POLLING_INTERVAL = 50;
  const MAX_REFRESH_WAIT = 60 * 1000;
  const MAX_REFRESH_RETRIES = MAX_REFRESH_WAIT / REFRESH_POLLING_INTERVAL;
  let refreshRetries = 0;

  const refreshAfterAdserverRequestSent = () => {
    // Prevent refresh before pubadsReady by polling googltag.pubadsReady.
    // Sadly it neither have an event to listen for,
    // nor can be hijacked with defineProperty.
    // (GPT do check hasOwnProperty before writing Boolean(true) to pubadsReady,
    // and pubadsReady have an initial value of undefined.)
    // Derived from: https://github.com/polydice/iCook/pull/3456
    // see also: https://polydice.slack.com/archives/CB0RD9RJS/p1530782080000276
    if (!window.googletag.pubadsReady && refreshRetries < MAX_REFRESH_RETRIES) {
      setTimeout(refreshAfterAdserverRequestSent, REFRESH_POLLING_INTERVAL);
      refreshRetries += 1;
      return;
    }
    window.googletag.cmd.push(() => {
      window.pbjs.setTargetingForGPTAsync();
      window.googletag.pubads().refresh();
    });
  };

  // Initialize Prebid.js
  window.pbjs.que.push(() => {
    window.pbjs.bidderSettings = {
      standard: {
        storageAllowed: true
      },
      ucfunnel: {
        bidCpmAdjustment(bidCpm) {
          return bidCpm * 0.8;
        }
      }
    };

    window.pbjs.addAdUnits(adUnits);

    pbjs.enableAnalytics({
      provider: 'appierAnalytics',
      options: {
        affiliateId: 'Ht99BxzT',
        configId: '5536ae358d6d45d082b6cbf879cfe7cc',
        sampling: 1,
        adSampling: 0.001,
        autoPick: 'manual'
      }
    });

    registerTDIDCallback();

    window.pbjs.setConfig({
      timeoutBuffer: 200,
      useBidCache: true,
      appier: { farm: 'tw' },
      priceGranularity: 'auto',
      userSync: {
        userIds,
        encryptedSignalSources: {
          sources: [
            {
              source: [
                'id5-sync.com',
                'pubcid.org',
                'uidapi.com',
                'liveramp.com',
                'crwdcntrl.net'
              ],
              encrypt: false
            }
          ]
        },
        auctionDelay: AUCTION_DELAY,
        filterSettings: {
          iframe: {
            bidders: '*',
            filter: 'include'
          }
        }
      },
      floors: {
        data: {
          schema: {
            fields: ['size', 'isMobile']
          },
          values: {
            '*|*': 0.05,
            '320x100|mobile': 0.25,
            '320x50|mobile': 0.25,
            '336x280|mobile': 0.13,
            '300x250|mobile': 0.13,
            '1x1|mobile': 0.13,
            '320x480|mobile': 0.2
          }
        },
        additionalSchemaFields: {
          isMobile: () => (isMobile ? 'mobile' : 'desktop')
        }
      }
    });
  });

  const HEADERBIDDING_TIMEOUT = 1200;

  const requestPrebid = () => {
    return new Promise((resolve) => {
      window.pbjs.que.push(() => {
        window.pbjs.requestBids({
          bidsBackHandler: () => {
            resolve();
          },
          timeout: HEADERBIDDING_TIMEOUT
        });
      });
    });
  };

  Promise.all([requestPrebid()]).then(() => {
    if (pbjs.initAdserverSet) return;
    pbjs.initAdserverSet = true;
    // This should only do once so we put it here
    window.pbjs.registerSignalSources();
    refreshAfterAdserverRequestSent();
  });
}
