/* eslint-disable no-console */
/* global pbjs */

import dayjs from 'dayjs';
import { detect as detectBrowser } from 'detect-browser';
import Cookies from 'js-cookie';

const PROVIDER = {
  TTD: 'unified_id'
};
const AD_ID_STORAGE_KEY = {
  TTD: `pbjs_${PROVIDER.TTD}`
};

/**
 * Send ad unified ID data to `fluentd.polydice.com`
 *
 * @param {{id: string, provider: string} data
 */
function sendData({ id, provider }) {
  const ENDPOINT = 'https://fluentd.polydice.com/event';
  const generalVariables = {
    tag: 'event.unique_id',
    visitor: Cookies.get('visitor') || '',
    seen_at: Date.now() / 1000,
    id,
    provider
  };

  const form = new FormData();
  form.append('json', JSON.stringify(generalVariables));

  const isSuccess = navigator.sendBeacon(ENDPOINT, form);
  if (!isSuccess) {
    navigator.sendBeacon(ENDPOINT, form);
  }
}

const isExpired = (provider) => {
  const key = `${AD_ID_STORAGE_KEY[provider]}_exp`;
  const expirationFlag = localStorage.getItem(key);

  if (!expirationFlag) {
    return true;
  }

  const now = dayjs();
  const expiredDate = dayjs(expirationFlag);
  return now.isAfter(expiredDate);
};

/**
 * The Delay in milliseconds of the auction to retrieve user ids via
 * the userId module before the auction starts.
 */
export const AUCTION_DELAY = 500;

/**
 * Send third-party AD ID to server, and retry next time (page load)
 * if any ID was not retrieved and(or) stored in web localStorage.
 *
 * The task is suitable for `User ID submodules that doesn't provide
 * `callback` option with Prebid.
 */
export function registerTDIDCallback() {
  if (!isExpired('TTD')) {
    return;
  }

  pbjs.onEvent('auctionEnd', () => {
    setTimeout(() => {
      const payload = localStorage.getItem(AD_ID_STORAGE_KEY.TTD);
      if (!payload) {
        return;
      }

      const tdid = JSON.parse(decodeURIComponent(payload)).TDID;
      if (tdid) {
        sendData({
          id: tdid,
          provider: PROVIDER.TTD
        });
      } else {
        console.error('tdid init failed');
      }

      pbjs.offEvent('auctionEnd');
    }, AUCTION_DELAY);
  });
}

const isSafari = () => {
  const browser = detectBrowser();
  return (
    browser &&
    (browser.name === 'safari' ||
      browser.name === 'ios' ||
      browser.name === 'ios-webview')
  );
};

const EXPIRES = isSafari() ? 0 : 365;

const pubCommon = {
  name: 'pubCommonId',
  storage: {
    type: 'html5',
    name: '_pubcid_icook',
    expires: EXPIRES
  }
};

const ttd = {
  name: 'unifiedId',
  params: {
    partner: 'nkd9bh0'
  },
  storage: {
    type: 'html5',
    name: AD_ID_STORAGE_KEY.TTD,
    expires: EXPIRES
  }
};

const id5PD = Cookies.get('_id5_pd') || '';
const id5 = {
  name: 'id5Id',
  params: {
    partner: 760,
    pd: decodeURIComponent(id5PD)
  },
  storage: {
    type: 'html5',
    name: 'id5id',
    expires: 90,
    refreshInSeconds: 8 * 3600
  }
};

/**
 * Lotame implements caching and refresh mechanism by itself.
 * REF - https://github.com/prebid/Prebid.js/blob/e8b8be53bbadb8e5d8e2666c6f09a2eeb46e4539/modules/lotamePanoramaIdSystem.js#L192
 */
const lotame = {
  name: 'lotamePanoramaId'
};

const uid2_params = {
  serverPublicKey:
    'UID2-X-P-MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEDou57Y4RCI3T5ybKvUb5EZRFcvV+8OIH0zZgckUH+XU+gB968dR+yDeQ6324QyLS8rb10+ZhhyEFUz8stZR8cw==',
  subscriptionId: 'NxSrFHENtT',
  uid2ApiBase: 'https://global.prod.uidapi.com'
};

if (Cookies.get('__uid2_email')) {
  uid2_params.email = Cookies.get('__uid2_email');
}

if (Cookies.get('__uid2_phone')) {
  uid2_params.phone = Cookies.get('__uid2_phone');
}

const uid2 = {
  name: 'uid2',
  params: uid2_params
};

const ats = {
  name: 'identityLink',
  params: {
    pid: '13624'
  },
  storage: {
    type: 'cookie',
    name: 'idl_env',
    expires: 15,
    refreshInSeconds: 1800
  }
};

const teads = {
  name: 'teadsId',
  params: {
    pubId: 24630
  }
};

const pair = {
  name: 'pairId',
  params: {
    liveramp: {} // LiveRamp is the clean-room facilitator
  }
};

if (window.atsenvelopemodule) {
  const liverampEmail = Cookies.get('__liveramp_email');
  if (liverampEmail)
    window.atsenvelopemodule.setAdditionalData({
      type: 'email',
      id: liverampEmail
    });

  const liverampPhone = Cookies.get('__liveramp_phone');
  if (liverampPhone)
    window.atsenvelopemodule.setAdditionalData({
      type: 'phoneNumber',
      id: liverampPhone
    });
}

/**
 * The user IDs for Ad which consists of:
 *  - PubCommonID
 *  - TTD (The Trade Desk) unified ID
 *  - lotamePanoramaID
 *  - ID5 Universal ID
 *  - uid2
 *  - teads
 */

const userIds = [pubCommon, ttd, lotame, id5, ats, teads, pair];

if (Cookies.get('__uid2_email') || Cookies.get('__uid2_phone')) {
  userIds.push(uid2);
}

export { userIds };
