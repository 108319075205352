import isbot from 'isbot';

const EXCLUDED_PATHS = ['/campaigns', '/brands', '/products', '/amp/products'];
const isVip = !!window.ICOOK && window.ICOOK.vip;
const isBot = isbot(navigator.userAgent);

export const shouldShowAds =
  (!isVip ||
    EXCLUDED_PATHS.some((path) => window.location.pathname.startsWith(path))) &&
  !isBot;

export const shouldPerformPrebid =
  shouldShowAds &&
  Object.keys(window.__iCook_adUnitRegistry).some(
    (key) => window.__iCook_adUnitRegistry[key].prebid
  );

export function insertScriptTagWithSrc(src: string) {
  const insertScriptTag = document.createElement('script');
  insertScriptTag.type = 'text/javascript';
  insertScriptTag.async = true;
  insertScriptTag.src = src;

  const referenceNode = document.getElementsByTagName('script')[0];
  referenceNode.parentNode!.insertBefore(insertScriptTag, referenceNode);
}

export const isMobile = document.documentElement.clientWidth <= 420;

const debugMatch = window.location.search.match(
  /icook_debug_pagespeed_disable=([\w,]+)/
);

export const pageSpeedDisableGroup = debugMatch ? debugMatch[1] : '';

export const isHkDomain =
  window.location.host.match(/.hk$/) ||
  (window.location.search.includes('hk=true') &&
    process.env.NODE_ENV === 'development');
