import { checkInterstitialAvailability } from './interstitialAd';

const hasInterstitial = checkInterstitialAvailability();

const adUnits = [
  {
    code: 'iCook_Recipe_Desktop_Top',
    mediaTypes: {
      banner: {
        sizeConfig: [
          { minViewPort: [0, 0], sizes: [] },
          { minViewPort: [992, 400], sizes: [[970, 250]] }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486980 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528078' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [[970, 250]],
          id: 'iCook_Recipe_Desktop_Top'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-2733D4B7E247E3EE1DE689AA39687D9' }
      },
      {
        bidder: 'teads',
        params: { pageId: 130046, placementId: 165820 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '2e18ac8d-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Middle1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321902 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528072' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Middle1'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-47B72862B38329EBFE2AD3A6AE8B6E97' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170246, placementId: 185132 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'bc36f511-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Middle2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321903 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528073' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Middle2'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-47B72862B24686241ED6D3B33AD7492B' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170247, placementId: 185133 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'ccef6dbf-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Step1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486995 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528051' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Step1'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176749 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-D234D87EA426AE6ECD74444AA869B6B6' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-77286DEAB892EB26C27A6E23DEB27E4' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'd4df60bf-1583-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'ca0eb4a6-16f8-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Step1_1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486996 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528070' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Step1_1'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-8A294732B874BA6D0B646A9286DDAB3A' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-77286DE2D4A922EDF7E79B2BA3322B4' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170265, placementId: 185151 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '94d0435b-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Step1_2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486997 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528071' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Step1_2'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-8A294732B7349BE313B9AE669EBE26D' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-627DE8A78EDD9873CE93333662BD7B73' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170266, placementId: 185152 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'a72364a3-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Step2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19445976 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528052' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Step2'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176750 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'f388f8c8-1583-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'e73bc144-16f8-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Step2_1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19445978 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528082' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Step2_1'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170267, placementId: 185153 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '828f5497-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Step2_2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19445979 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528083' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Step2_2'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170268, placementId: 185154 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '9453116b-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottom1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486968 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528074' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Bottom1'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-77286DE236B7462BFB38299A74E28BD2' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-E2B47DABDA8E98EE09E8DE7B383862B' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170241, placementId: 185127 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'e1a978b6-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottom3',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486969 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528075' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Bottom3'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170242, placementId: 185128 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'f45783e6-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottoms1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486971 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528076' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Bottoms1'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-E2B47DAB72E73977074EE23287E3B66A' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-E2B47DAB7BA3DEAE1E9D63E2437E932' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170243, placementId: 185129 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '04f7806d-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottoms2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486978 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528077' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Bottoms2'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-AA7B36EB82A2E3B7C8D9999772A6BABD' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-2736D4E62A86A4D2F9497838B223389' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170244, placementId: 185130 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '1682cbb3-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Top1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321897 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528057' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Top1'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170213, placementId: 185102 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '5303a551-1584-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Top2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321898 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528058' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Top2'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170214, placementId: 185103 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '6db1bcb5-1584-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Middle1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321892 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528059' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Middle1'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170201, placementId: 185090 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '88f17528-1584-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Middle2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321893 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528060' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Middle2'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170202, placementId: 185091 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'a195071f-1584-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Bottom1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321890 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528061' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Bottom1'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 167441, placementId: 182297 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'b53da8e3-1584-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Bottom2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321891 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528062' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Bottom2'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170197, placementId: 185086 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'c8b1d063-1584-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Top',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321904 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528049' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Top'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 165818 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-34B8763ADB6B94BAFD8498376464AB6' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-47B4938DE7A66ED7F364B6E92424D74' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'aef2ef23-1583-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '767aeb4c-16f8-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Middle',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321901 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528050' }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 165817 }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Middle'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-8A2AB393ED247BEE0B2ED6A7838662AB' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'c19d8954-1583-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'a772f5c8-16f8-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottom',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486967 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '540945405' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Bottom'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 165816 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-77286DEAB484676B0B69DBA8AB68B7BE' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-8A29473E86D343B3FB79A2893D7A9446' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '097f49e0-1584-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '07c1070c-16f9-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottoms',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528055' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Bottoms'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176751 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-2736D4EA894D36E6C867A8BDB86D898E' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-9A29BE86D3BA9A7A14EBBBB778236266' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '20ee038f-1584-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '29ae7f78-16f9-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Top',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321896 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528042' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Top'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176743 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-34B8763A6424BDA803B8826243867AA9' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-BE78B43A4E399A47C728ED38A42E8994' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '65960221-1582-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'dab6b405-16f7-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'clickforce',
        params: { zone: '21778' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Middle',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321895 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528043' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Middle'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176744 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-BE78B43A488D22391AEA27874DD8822' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-627DE8A48DB44A9BF7E6824462E2A46' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '8c2469d3-1582-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'f99e7b0e-16f7-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'clickforce',
        params: { zone: '21779' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Bottom',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528044' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Bottom'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176745 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-47B4938D343BDE790E833E47E4A38E76' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-BE78B43A48BA6EA20A834876E3E39BA' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'f18ce3e8-1582-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '1c668a8c-16f8-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'clickforce',
        params: { zone: '21780' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Bottom_Sticky',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 0],
            sizes: [[320, 50]]
          },
          {
            minViewPort: [336, 600],
            sizes: [
              [1, 1],
              [320, 100]
            ]
          },
          {
            minViewPort: [768, 0],
            sizes: []
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14321889 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528046' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [320, 50],
            [320, 100]
          ],
          id: 'iCook_Recipes_Bottom_Sticky'
        }
      },
      {
        bidder: 'impactify',
        params: { appId: 'icook.tw', format: 'screen', style: 'impact' }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176748 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '45be1db2-1583-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '65167d8a-1583-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '58d5fdd6-16f8-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'clickforce',
        params: { zone: '21781' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Sidebar_Top',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14486988 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528079' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [250, 250],
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Sidebar_Top'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170264, placementId: 185150 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '4328ba3d-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Sidebar_Middle',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528080' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [250, 250],
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipe_Sidebar_Middle'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170263, placementId: 185149 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '5baf718e-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Sidebar_Top',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14487003 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528063' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [250, 250],
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Sidebar_Top'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170211, placementId: 185100 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '1983adfd-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Sidebar_Middle',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14487001 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528064' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [250, 250],
            [300, 250],
            [336, 280]
          ],
          id: 'iCook_Recipes_Sidebar_Middle'
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170209, placementId: 185098 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '2c7827fd-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Sidebar_Bottom',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [[300, 600]]
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528081' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [250, 250],
            [300, 600]
          ],
          id: 'iCook_Recipe_Sidebar_Bottom'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-9A264B62898286EDF4A9826888D923BE' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-D23E9DE39BB893E81D7E794D8AD674DE' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-772A36A2EABDE98D076963B7EBB4862' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170249, placementId: 185135 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '6e1bc05a-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Sidebar_Bottom2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '540945424' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170250, placementId: 185136 }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Sidebar_Bottom',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [[300, 600]]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 14487000 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528065' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [250, 250],
            [300, 600]
          ],
          id: 'iCook_Recipes_Sidebar_Bottom'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-BE7ADBA732744BD4F79AB99EAEB382DD' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-34BA27AB3EED2D93C3E6E9A37834EA34' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-E2B6E76BA3E74866F723B89BBB438D64' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170203, placementId: 185092 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '41735758-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Sidebar_Bottom2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '540945420' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170204, placementId: 185093 }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Interstitial',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [300, 400],
            sizes: [[320, 480]]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528048' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [[320, 480]],
          id: 'iCook_Recipe_Interstitial'
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-AA77362BAADD689308E934B9A7ED22BB' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '8e6296c4-1583-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'clickforce',
        params: { zone: '21783' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Sidebar_Bottom4',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19870995 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528067' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipes_Sidebar_Bottom4',
          sizes: [
            [300, 250],
            [336, 280]
          ]
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170206, placementId: 185095 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '6cbf5c9d-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Sidebar_Bottom6',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19871177 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528069' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipes_Sidebar_Bottom6',
          sizes: [
            [300, 250],
            [336, 280]
          ]
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170208, placementId: 185097 }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Sidebar_Bottom4',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19871185 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528088' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipe_Sidebar_Bottom4',
          sizes: [
            [300, 250],
            [336, 280]
          ]
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170252, placementId: 185138 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'bc8ccd48-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Bottoms',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 400],
            sizes: [
              [1, 1],
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [768, 400],
            sizes: []
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559546470' }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176746 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-9A29BE8642792BA9164798DD7848A94' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-D234D87E934EADAAFD7AE36AB97EA448' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '071fc309-1583-11ee-a6a7-f23c9173ed43' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '3c3596a5-16f8-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Bottoms1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559546474' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170198, placementId: 185087 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'e6e0be71-1584-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Bottoms2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559546476' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170199, placementId: 185088 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'ff36e36e-1584-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Bottomss',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [300, 400],
            sizes: [
              [1, 1],
              [320, 480]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19871180 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528045' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipes_Bottomss',
          sizes: [[320, 480]]
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170200, placementId: 185089 }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-BE7A229467B4744407BBDE6A634783D9' }
      },
      {
        bidder: 'ad2iction',
        params: { id: '2c6089b1-1583-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Sidebar_Bottom3',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19870983 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528066' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipes_Sidebar_Bottom3',
          sizes: [
            [300, 250],
            [336, 280]
          ]
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170205, placementId: 185094 }
      },
      {
        bidder: 'ad2iction',
        params: { id: '54245e50-1585-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipes_Sidebar_Bottom5',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19871064 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528068' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipes_Sidebar_Bottom5',
          sizes: [
            [300, 250],
            [336, 280]
          ]
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170207, placementId: 185096 }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Sidebar_Bottom3',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [992, 400],
            sizes: [[250, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      { bidder: 'appnexus', params: { placementId: 19871181 } },
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528084' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipe_Sidebar_Bottom3',
          sizes: [
            [300, 250],
            [336, 280]
          ]
        }
      },
      {
        bidder: 'teads',
        params: { pageId: 170251, placementId: 185137 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'a9a296b6-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottomss1',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528089' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipe_Bottomss1',
          sizes: [
            [300, 250],
            [336, 280]
          ]
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-47B49384266E2BEDC364B6E92424DA6' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-D234D874988D2492FBA69AD8E6E642D' }
      },
      {
        bidder: 'teads',
        params: { pageId: 170245, placementId: 185131 }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'd3ddf18c-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottomss2',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [768, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          },
          {
            minViewPort: [992, 400],
            sizes: [[300, 250]]
          },
          {
            minViewPort: [1200, 400],
            sizes: [
              [300, 250],
              [336, 280]
            ]
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528090' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          id: 'iCook_Recipe_Bottomss2',
          sizes: [
            [300, 250],
            [336, 280]
          ]
        }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-2736D4E62E8EA6A3C9497838B223376' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-77286DE836A8AE96CBB89D4498377A7D' }
      },
      {
        bidder: 'ad2iction',
        params: { id: 'ec60a885-1586-11ee-a6a7-f23c9173ed43' }
      }
    ]
  },
  {
    code: 'iCook_Recipe_Bottom_Sticky',
    mediaTypes: {
      banner: {
        sizeConfig: [
          {
            minViewPort: [0, 0],
            sizes: [[320, 50]]
          },
          {
            minViewPort: [336, 600],
            sizes: [
              [320, 100],
              [1, 1]
            ]
          },
          {
            minViewPort: [768, 0],
            sizes: []
          }
        ]
      }
    },
    bids: [
      {
        bidder: 'openx',
        params: { delDomain: 'icook-d.openx.net', unit: '559528056' }
      },
      {
        bidder: 'ix',
        params: {
          siteId: '530523',
          sizes: [
            [320, 50],
            [320, 100]
          ],
          id: 'iCook_Recipe_Bottom_Sticky'
        }
      },
      {
        bidder: 'impactify',
        params: { appId: 'icook.tw', format: 'screen', style: 'impact' }
      },
      {
        bidder: 'ucfunnel',
        params: { adid: 'ad-E2B6EDA997A79EAA17497383A6A87739' }
      },
      {
        bidder: 'teads',
        params: { pageId: 137000, placementId: 176752 }
      },
      ...(hasInterstitial
        ? []
        : [
            {
              bidder: 'ad2iction',
              params: { id: '45be1db2-1583-11ee-a6a7-f23c9173ed43' }
            },
            {
              bidder: 'ad2iction',
              params: { id: '65167d8a-1583-11ee-a6a7-f23c9173ed43' }
            },
            {
              bidder: 'ad2iction',
              params: { id: '59f62a4b-16f9-11ee-a6a7-f23c9173ed43' }
            }
          ])
    ]
  }
];

export default adUnits;
